import React from "react";
// import { useNavigate, useParams } from "react-router";
// import { useForm } from "../../util/hooks";
import { gql } from "@apollo/client";
// import moment from "moment";
// import { animateScroll } from "react-scroll";

import usePageTracking from "../../util/usePageTracking";
// import MessageInputField from "../../components/customer/MessageInputField";
// import ChatFormatted, {
//   FETCH_MESSAGES_BY_CHAT,
// } from "../../components/customer/ChatFormatted";
// import bandhorizontal from "../../images/bandhorizontal.png"; // Tell webpack this JS file uses this image

// import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
// import CardInputField from "../../components/customer/CardInputField";
// import humpty from "../../images/humpty.png"; // Tell webpack this JS file uses this image
// import { FETCH_EMOJIS, FETCH_TODAYS_WORLD_PHRAZE } from "./AdminDashboard";
// import EmptyEmojiBox from "../../components/customer/EmptyEmojiBox";
// import EmojiBox from "../../components/customer/EmojiBox";
// import EmojiStatsBox, {
//   FETCH_EMOJI_BY_INDEX,
// } from "../../components/customer/EmojiStatsBox";
// import bandVid from "../../images/bandVid.mov"; // Tell webpack this JS file uses this image
// import { customerClient } from "../../GraphqlApolloClients";
// import { AuthContext } from "../../context/auth";
// import { FETCH_CUSTOMER } from "./Chat";
// import html2canvas from "html2canvas";

function Landing(props) {
  usePageTracking();

  return (
    <div className="flex flex-col items-center relative justify-start lg:justify-center bg-white overflow-hidden h-full min-h-screen lg:h-screen w-screen py-4 px-6"></div>
  );
}

export const FETCH_PLAYER_CHARS_TODAY = gql`
  query getPlayersCharsToday {
    getPlayersCharsToday {
      id
      customerId
      customerFirstName
      customerLastName
      worldPhrazeId
      dayNum
      selectedChar
      charIndexInWorldPhraze
      numOfShares
      createdAt
      lastSharedAt
    }
  }
`;

export const FETCH_PLAYERS_PHRAZE_TODAY = gql`
  query getPlayersPhrazeToday {
    getPlayersPhrazeToday {
      id
      customerId
      phrazeId
      dayNum
      randStartArrangement
      guessedChars
      numOfCorrectGuesses
      numOfIncorrectGuesses
      score
      isCompleted
      numOfShares
      numOfPhrazeyWeekShares
      startedAt
      lastEditedAt
      lastSharedAt
      isEligibleForPrize
      status
    }
  }
`;

// const SELECT_PLAYER_CHAR_MUTATION = gql`
//   mutation selectPlayerChar($selectedChar: String!) {
//     selectPlayerChar(selectedChar: $selectedChar) {
//       id
//       customerId
//       customerFirstName
//       customerLastName
//       worldPhrazeId
//       dayNum
//       selectedChar
//       charIndexInWorldPhraze
//       numOfShares
//       createdAt
//       lastSharedAt
//     }
//   }
// `;

export const FETCH_TODAYS_PHRAZE = gql`
  query getTodaysPhraze {
    getTodaysPhraze {
      id
      dayNum
      phrase
      phraseLength
      numOfUniqueChars
      averagePhrazeScore
      totalNumOfPlayers
      totalNumOfWinners
      numOfNewPlayers
      numOfShares
      numOfPhrazeyWeekShares
      createdAt
      releaseDate
      isReminderSent
      gameTimerEndsDate
      prizeMoneyClaimed
      numOfTimedGameWinners
    }
  }
`;

export const PROCESS_EMOJI_PAYMENT_RESULT = gql`
  mutation processEmojiPaymentResult($emojiId: String!) {
    processEmojiPaymentResult(emojiId: $emojiId) {
      id
      status
      index
    }
  }
`;

export const BUY_EMOJI_MUTATION = gql`
  mutation buyEmoji(
    $index: Int!
    $email: String!
    $caption: String!
    $emojiIcon: String!
    $version: Int!
    $link: String
  ) {
    buyEmoji(
      index: $index
      email: $email
      caption: $caption
      emojiIcon: $emojiIcon
      version: $version
      link: $link
    ) {
      id
      index
      paymentIntentClientSecret
    }
  }
`;

export const UPDATE_GUESSED_CHARS_MUTATION = gql`
  mutation updateGuessedChars(
    $guessedChars: String!
    $randStartArrangement: String!
  ) {
    updateGuessedChars(
      guessedChars: $guessedChars
      randStartArrangement: $randStartArrangement
    ) {
      id
      customerId
      phrazeId
      dayNum
      randStartArrangement
      guessedChars
      numOfCorrectGuesses
      numOfIncorrectGuesses
      score
      isCompleted
      numOfShares
      numOfPhrazeyWeekShares
      startedAt
      lastEditedAt
      lastSharedAt
    }
  }
`;

export const FETCH_NUM_OF_BANDS_LEFT = gql`
  query getNumOfBandsLeft {
    getNumOfBandsLeft
  }
`;

export const PROCESS_BAND_PAYMENT_RESULT = gql`
  mutation processBandPaymentResult($bandId: String!) {
    processBandPaymentResult(bandId: $bandId) {
      id
      status
    }
  }
`;
export const BUY_BAND_MUTATION = gql`
  mutation buyBand(
    $firstName: String!
    $lastName: String!
    $email: String!
    $country: String!
    $streetAddress: String!
    $city: String!
    $state: String!
    $zip: String!
    $size: String!
  ) {
    buyBand(
      firstName: $firstName
      lastName: $lastName
      email: $email
      country: $country
      streetAddress: $streetAddress
      city: $city
      state: $state
      zip: $zip
      size: $size
    ) {
      id
      paymentIntentClientSecret
    }
  }
`;

export const SHARE_PHRAZE_MUTATION = gql`
  mutation invokedSharePhraze($playerPhrazeId: String) {
    invokedSharePhraze(playerPhrazeId: $playerPhrazeId) {
      id
    }
  }
`;
export const SHARE_WORLD_PHRAZE_MUTATION = gql`
  mutation invokedShareWorldPhraze {
    invokedShareWorldPhraze
  }
`;

// export const FETCH_EMOJIS = gql`
//   query getEmojis($status: Int!) {
//     getEmojis(status: $status) {
//       id
//       index

//       link
//       caption
//       emojiIcon
//       version
//       netAmount
//       numOfClicks
//       numOfHovers
//       isApprovedByAdmin
//       status
//       paymentConfirmedAt
//     }
//   }
// `;

export default Landing;
