import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { AuthProvider } from "./context/auth";

import Landing from "./pages/customer/Landing";

const stripePromise = loadStripe(
  process.env.NODE_ENV === "production"
    ? "pk_live_51LaQZbEU2fOd53K4TXcRPKWXKe41gxg4o7C9euUI4WVDLfVy2OouXYVDJsL4p27CcORIC9BDA6PQ21mJr3rW71BM00wfcGLq0h"
    : "pk_test_51LaQZbEU2fOd53K4tHdZIEr86w9xuA1P8tbxop1h5rb7S0VT2ZtvGrvc0VPfxz0GcGalyeKjWZ7XRVfG6uXCaba000Ylwsqmb2"
);

function App() {
  return (
    <Elements stripe={stripePromise}>
      <BrowserRouter>
        <Routes>
          <Route
            exact
            path="/"
            element={
              <AuthProvider>
                <Landing />
              </AuthProvider>
            }
          />
          {/* 
          <Route
            exact
            path="/:emojiIndex"
            element={
              <AuthProvider>
                <Landing />
              </AuthProvider>
            }
          /> */}

          {/* <Route exact path="/:chatId" element={<Landing />} /> */}

          {/* <Route exact path="/merch" element={<GetBand />} /> */}
          {/* <Route exact path="/band/:bandId" element={<Band />} />

          <Route exact path="/privacy" element={<Terms />} />
          <Route exact path="/terms" element={<Terms />} /> */}
          {/* 
          <Route
            exact
            path="/login"
            element={
              <AuthProvider>
                <CustomerLogin />
              </AuthProvider>
            }
          /> */}
          {/* <Route
            exact
            path="/signup"
            element={
              <AuthProvider>
                <CustomerSignUp />
              </AuthProvider>
            }
          /> */}

          {/* <Route exact path="/sn" element={<SNPage />} /> */}

          {/* <Route
            exact
            path="/signup/:signedUpFor"
            element={
              <AuthProvider>
                <CustomerSignUp />
              </AuthProvider>
            }
          /> */}

          {/* <Route
            exact
            path="/chat"
            element={
              <AuthProvider>
                <Chat />
              </AuthProvider>
            }
          /> */}

          {/* <Route
            exact
            path="/resetPassword"
            element={
              <AuthProvider>
                <CustomerPasswordReset />
              </AuthProvider>
            }
          />

          <Route
            exact
            path="/resetPassword/:resetCode"
            element={
              <AuthProvider>
                <CustomerPasswordReset />
              </AuthProvider>
            }
          /> */}

          {/* <Route
            exact
            path="/prizePromo"
            element={
              <AuthProvider>
                <CommentPrize />
              </AuthProvider>
            }
          /> */}

          {/* <Route
            exact
            path="/trackPrize/:playerPhrazeId"
            element={
              <AuthProvider>
                <PlayerPhrazeW />
              </AuthProvider>
            }
          /> */}

          {/* <Route
            exact
            path="/adminLogin"
            element={
              <AdminAuthProvider>
                <AdminLogin />
              </AdminAuthProvider>
            }
          /> */}
          {/* <Route
            exact
            path="/adminDashboard"
            element={
              <AdminAuthProvider>
                <AdminDashboard />
              </AdminAuthProvider>
            }
          /> */}

          {/* <Route
            exact
            path="/adminChat/:customerId"
            element={
              <AdminAuthProvider>
                <AdminChat />
              </AdminAuthProvider>
            }
          /> */}
        </Routes>
      </BrowserRouter>
    </Elements>
  );
}

export default App;
