import { split } from "@apollo/client";
import {
  ApolloClient,
  // createHttpLink,
  InMemoryCache,
} from "@apollo/client/core";
import { setContext } from "@apollo/client/link/context";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { createUploadLink } from "apollo-upload-client";

const backendURI =
  process.env.NODE_ENV === "production"
    ? "https://humpty.herokuapp.com/graphql"
    : "http://localhost:4000/graphql";

const wsURI =
  process.env.NODE_ENV === "production"
    ? `wss://humpty.herokuapp.com/subscriptions`
    : "ws://localhost:4000/subscriptions";

const httpLink = createUploadLink({
  uri: backendURI,
  // credentials: "include",
});

const wsLink = new WebSocketLink({
  uri: wsURI,
  options: {
    reconnect: true,
    lazy: true,
    // timeout: 20000,
    onError: (error) => {
      // error.message has to match what the server returns.
      if (
        error.message.contains("authorization") &&
        (localStorage.getItem("adminJwtToken") ||
          localStorage.getItem("jwtToken"))
      ) {
        // Reset the WS connection for it to carry the new JWT.
        wsLink.subscriptionClient.close(false, false);
      }
    },
    connectionParams: () => ({
      Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
      AdminAuth: `Bearer ${localStorage.getItem("adminJwtToken")}`,
    }),
  },
});

const adminAuthLink = setContext(() => {
  const adminToken = localStorage.getItem("adminJwtToken");
  return {
    headers: {
      Authorization: adminToken ? `Bearer ${adminToken}` : "",
    },
  };
});
const adminLink = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLink,
  adminAuthLink.concat(httpLink)
);
// export const adminClient = null;
export const adminClient = new ApolloClient({
  // link: authLink.concat(httpLink),
  link: adminLink,
  // link: adminAuthLink.concat(httpLink),
  uri: backendURI,

  cache: new InMemoryCache({ addTypename: false }),
  resolvers: {},
});

const customerAuthLink = setContext(() => {
  const customerToken = localStorage.getItem("jwtToken");

  return {
    headers: {
      Authorization: customerToken ? `Bearer ${customerToken}` : "",
    },
  };
});

const customerLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  customerAuthLink.concat(httpLink)
);
export const customerClient = new ApolloClient({
  // link: authLink.concat(httpLink),
  link: customerLink,
  // link: customerAuthLink.concat(httpLink),
  uri: backendURI,

  cache: new InMemoryCache({ addTypename: false }),
  resolvers: {},
});
